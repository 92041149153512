import { ClerkLoaded, ClerkLoading, SignIn, useClerk } from '@clerk/nextjs'
import { clerkAppearanceOptions } from '@themes/ClerkThemeOptions'
import * as Colors from '@themes/Colors'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ActivityFooter } from '~/Components/Fillers/ActivityFooter'

export default function ClerkLoginPage() {
  const { loaded } = useClerk()

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (!loaded) {
        window.location.reload()
      }
    }, 3000)

    if (loaded) return () => clearTimeout(timeout)

    return () => clearTimeout(timeout)
  }, [loaded])

  return (
    <View
      style={{
        ...StyleSheet.absoluteFillObject,
        backgroundColor: Colors.backgroundGray,
      }}
    >
      <View style={{ flexGrow: 1, flexShrink: 1, flexBasis: '0%' }}>
        <View style={{ flexGrow: 1 - Math.sqrt(5) / 2 }} />
        <ClerkLoading>
          <ActivityFooter active />
        </ClerkLoading>
        <ClerkLoaded>
          <SignIn
            appearance={{ ...clerkAppearanceOptions }}
            fallbackRedirectUrl="/login-redirect"
          />
        </ClerkLoaded>
      </View>
    </View>
  )
}
